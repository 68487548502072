const withSeparator = (words: (number | string)[], separator: string = " "): string => {
  if (!words) {
    return;
  }

  return words
    .filter((item) => (typeof item === "string" || typeof item === "number") && String(item).length > 0)
    .map((item) => String(item).trim())
    .join(separator);
};

export { withSeparator };
